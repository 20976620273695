import dateFormat from "dateformat"
import { Link } from "gatsby"
import React,{useState, useEffect} from "react"
import { Container } from "react-bootstrap"
import { blog } from "../../lib/urls"
import "./NewsListingBanner.scss"
import ScrollAnimation from "react-animate-on-scroll"


const NewsListingBanner = ({ newsData, strapi_id, isPreviewEnabled }) => {
  const {
    banner_image,
    category,
    date,
    imagetransforms,
    read_time,
    slug,
    topics,
    title,
    video_url,
    content,
    tile_image
  } = newsData

  const [firstParagraph, setFirstParagraph] = useState('');
  const htmlContent = content?.data?.content?content?.data?.content
                      :(isPreviewEnabled&&content)?content:""

  useEffect(() => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const firstPElement = doc.querySelector('p');
    setFirstParagraph(firstPElement.outerHTML);
  }, [htmlContent]);
  

  return (
    
    <div className="featured-news-section" key={strapi_id}>
    <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
    {(newsData.banner_image||newsData.title)&&
      <Container>
        <div className="image-content-news-banner">
          <div className="image-section">
            <Link href={`${blog}${slug}/`}>
              <img src={banner_image?.url?banner_image?.url:tile_image?.url} alt={`${title}`} classNames="img-fluid"/>
            </Link>
          </div>
          <div className="text-section">
            <Link href={`${blog}${slug}/`}>
              <h1>{title}</h1>
            </Link>
            <div className="time-date">
              <p className="time">{read_time}</p>
              {read_time && <span>/</span>}
              <p className="date">{dateFormat(date, "mmm dd, yyyy")}</p>
            </div>
            {(content?.data?.content || (content&&isPreviewEnabled))&&
            <p className="description">
                <div dangerouslySetInnerHTML={{ __html: firstParagraph }} />
            </p>
            }
            <Link to={`${blog}${slug}/`}>
              <button className="button button-outline-grey">
                continue reading
              </button>
            </Link>
          </div>
        </div>
      </Container>
      }
      </ScrollAnimation>
    </div>
  )
}

export default NewsListingBanner
